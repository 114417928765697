export const
    A11y          = {id: 'acc', name: "A11y"},
    Android       = {id: 'android', name: 'Android'},
    Angular       = {id: 'angular', image: 'angular-icon.svg'},
    Apple         = {id: 'apple', name: 'iOS'},
    AWS           = {id: 'aws', name: 'AWS'},
    C             = {id: 'c', image: 'c.svg'},
    Capistrano    = {id: 'capistrano', image: 'capistrano.svg'},
    CreateML      = {id: 'create-ml', name: "Create ML"},
    CPP           = {id: 'c++', name: 'C++'},
    CSharp        = {id: 'c-sharp', image: 'c-sharp.svg'},
    ComputerVision= {id: 'computer-vision', name: 'Computer Vision'},
    CoreML        = {id: 'core-ml', name: "Core ML"},
    Dask          = {id: 'dask', name: "Dask"},
    DevOps        = {id: 'devops', name: "DevOps"},
    Docker        = {id: 'docker', name:'Docker'},
    Elixir        = {id: 'elixir', image: 'elixir.png'},
    Firebase      = {id: 'firebase', image: 'firebase.svg'},
    Go            = {id: 'go', name: 'Go'},
    GoogleCloud   = {id: 'google-cloud', name: 'GCP'},
    HuggingFace   = {id: 'hugging-face', name: "🤗"},
    Heroku        = {id: 'heroku', image: 'heroku.svg'},
    Java          = {id: 'java', image: 'java.svg'},
    Javascript    = {id: 'javascript', name: 'JavaScript'},
    Jupyter       = {id: 'jupyter', name: 'Jupyter'},
    Keras         = {id: 'keras', image: 'keras.svg'},
    Kotlin        = {id: 'kotlin', name: 'Kotlin'},
    Matplotlib    = {id: 'matplotlib', name: "Matplotlib"},
    Metal         = {id: 'metal', name: "Metal"},
    MLOps         = {id: 'mlops', name: "MLOps"},
    NLTK          = {id: 'nltk', name: "NLTK"},
    NodeJs        = {id: 'nodejs', image: 'nodejs-icon.svg'},
    ObjC          = {id: 'objective-c', name: '[C]'},
    OpenCV        = {id: 'opencv', image: 'opencv.svg'},
    Pandas        = {id: 'pandas', name: "Pandas"},
    PHP           = {id: 'php', image: 'php-flat.svg'},
    Postgres      = {id: 'postgresql', name: 'PostgreSQL'},
    Python        = {id: 'python', name: 'Python'},
    Pytorch       = {id: 'pytorch', name: 'Pytorch'},
    R             = {id: 'R', name: 'R'},
    Rails         = {id: 'rails', name: 'Rails'},
    React         = {id: 'react', name: 'React'},
    ReactNative   = {id: 'react native', name: 'React Native'},
    ReactRouter   = {id: 'react-router', image: 'react-router.svg'},
    Regression    = {id: 'lin-log-reg', name: "Regression"},
    Redis         = {id: 'redis', image: 'redis.svg'},
    Redux         = {id: 'redux', image: 'redux.svg'},
    Ruby          = {id: 'ruby', name: 'Ruby'},
    Saltstack     = {id: 'saltstack', image: 'saltstack.svg'},
    ScikitLearn   = {id: 'scikit-learn', name: 'SKLearn'},
    StatAna       = {id: 'stats', name: "Statistical Analysis"},
    Swift         = {id: 'swift', name: 'Swift'},
    SwiftUI       = {id: 'swift', name: 'SwiftUI'},
    TensorFlow    = {id: 'tensorflow', name: 'TF'},
    TFLite        = {id: 'tf-lite', name: "TFLite"},
    TFMicro       = {id: 'tf-micro', name: "TFMicro"},
    Vue           = {id: 'vue', name: 'VueJS'}
